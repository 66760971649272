/* eslint-disable react/no-danger */
import React from 'react'
import Typography from '@material-ui/core/Typography'

export default function ProductName({ productName }) {
  return (
    <Typography component="h1" variant="h3" color="secondary">
      <span dangerouslySetInnerHTML={{ __html: productName }} />
    </Typography>
  )
}
