import React from 'react'
import EngineeringTemplate from 'components/templates/EngineeringTemplate/EngineeringTemplate'

export default function EngineeringPhonometryPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Fonometria'

  const description =
    'Per la corretta gestione delle sedi e postazioni lavorative e per la valutazione dei rischi da agenti fisici, biologici e chimici ING GROUP offre i seguenti rilievi strumentali:<br> • Rumore in ambiente lavorativo (D.Lgs. 81/2008);<br> • Rumore Esterno, Impatto Acustico (D.Lgs. 447/1995)<br><br> ING GROUP dispone di tutte le strumentazioni necessarie all’esecuzione dei rilievi strumentali individuati. Per i rilievi dei livelli di rumore disponiamo del FONOMETRO 01dB – Solo.<br><br> <b>I rilievi fonometrici sono assicurati:</b><br> In <b>AMBIENTE DI LAVORO</b> – Rilievi differenziati in più punti dell’ambiente di lavoro indipendentemente dalle funzioni operanti, per rilevare il livello di rumore cui è potenzialmente esposto ogni operatore nella giornata lavorativa tipo<br> Per <b>FUNZIONE AZIENDALE</b> – Rilievo effettuato seguendo una funzione aziendale nell’espletamento delle sue mansioni tipo in un periodo di tempo che sia rappresentativo della giornata lavorativa standard, per rilevare il livello di rumore cui è potenzialmente esposto la specifica funzione aziendale nella giornata lavorativa tipo<br><br> La conduzione dei rilievi strumentali rispetta questo schema, per assicurare l’analisi di dettaglio di tutti gli eventuali rischi presenti valutati in funzione dei seguenti parametri:<br> • Tipologia di rischio;<br> • Postazione lavorativa;<br> • Ambiente di lavoro;<br> • Funzione aziendale;<br> • Mansioni di dettaglio.<br><br> In questi anni abbiamo misurato i livelli di rumore in realtà industriali di produzione e di servizi quali sale contazione e call center, oltre che in automezzi, treni e funicolari ed in ambienti esterni quali grandi stadi dedicati al calcio.'

  return (
    <>
      <EngineeringTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
