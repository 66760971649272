/* eslint-disable react/no-danger */
import React from 'react'
import Typography from '@material-ui/core/Typography'

export default function Description({ description, description1 }) {
  return (
    <Typography className="product-description" variant="body2" color="secondary">
      {description1 ? (
        <span dangerouslySetInnerHTML={{ __html: description1 }} />
      ) : (
        <span dangerouslySetInnerHTML={{ __html: description }} />
      )}
    </Typography>
  )
}
