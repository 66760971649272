import React from 'react'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import { useThemeContext } from 'context/ThemeContext'
import SEO from 'components/elements/SEO/SEO'
import { useStaticQuery, graphql } from 'gatsby'
import Typography from '@material-ui/core/Typography'
import CustomImage from 'components/foundations/Image/CustomImage'
import Link from 'components/foundations/Link/Link'
import { PageContent, ProductDetails } from '../ProductTemplate/ProductTemplate.styles'
import ProductName from '../ProductTemplate/ProductName'
import Description from '../ProductTemplate/Description'

export default function EngineeringTemplate({ seoKeywords, productName, description }) {
  const EngineeringQuery = Object.values(
    useStaticQuery(graphql`
      query EngineeringTQuery {
        allSitePage(filter: { path: { regex: "/^/servizi/ingegneria/" } }) {
          nodes {
            path
          }
        }
      }
    `).allSitePage.nodes,
  )

  const { isMobile } = useThemeContext()

  const productURIs = EngineeringQuery.map((paths) => Object.values(paths).toString())

  function splitName(string) {
    return string.split('/')[3]
  }

  return (
    <>
      <section className="consulting closer">
        <SEO title={productName} keywords={seoKeywords} />
        <h3 className="title">Ingegneria e Antincendio</h3>
        <CustomImage filename={'engineering.jpg'} />
        <div className="consulting-grid">
          <div className="consulting-grid-list">
            <p style={{ marginBottom: '0.5rem' }}>CATEGORIE</p>
            <div className="separator" />
            <GridList cols={isMobile ? 1 : 1} cellHeight="auto" className="gridListConsulting">
              {productURIs.map((productURL) => (
                <GridListTile className="single-consulting" key={splitName(productURL)}>
                  <Link to={productURL}>
                    <Typography className="consulting-info" component="p">
                      {splitName(productURL).replace(/-/g, ' ')}
                    </Typography>
                  </Link>
                  <hr />
                </GridListTile>
              ))}
            </GridList>
          </div>
          <div className="consulting-grid-content">
            <PageContent className="consultingTemplate">
              <ProductDetails>
                <ProductName productName={productName} />
                <Description description={description} />
              </ProductDetails>
            </PageContent>
          </div>
        </div>
      </section>
    </>
  )
}
